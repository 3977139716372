$=jQuery;

export class Pop {
   constructor() {
      this.init();
   }
   
   init() {
      const self = this;

      console.log('pop inited');
      this.pop = document.querySelector('.pop');
      this.popContent = document.querySelector('.pop--content');
      this.popClose = document.querySelectorAll('.pop--close, .pop--backdrop');
      // this.pop.addEventListener('click', this.openPop.bind(this));

      this.popClose.forEach((el) => {
         el.addEventListener('click', this.closePop.bind(this));
      });

      $('.block-gallery li').on('click', function(){
         $(this).addClass('cur');
         self.openPop(this);
      });

      $('.pop--prev, .pop--next').on('click', function(e){
         e.preventDefault();
         let cur = $('li.cur');
         let next = $(this).hasClass('pop--next') ? cur.next() : cur.prev();
         if(next.length) {
            cur.removeClass('cur');
            next.addClass('cur');
            $('.pop--content > *').addClass('fade');
            setTimeout(function(){
               self.openPop(next[0]);
            }, 150);
         } else {
            self.closePop();
         }
      });

      document.onkeyup = function(e) {
         e = e || window.event;
         if (e.keyCode == 27) {
            self.closePop();
         } else if(e.keyCode == 37) {
            $('.pop--prev').click();
         } else if(e.keyCode == 39) {       
            $('.pop--next').click();
         }
      };
   }

   openPop(el) {
      console.log(el);
      this.popContent.innerHTML = el.innerHTML;
      this.pop.classList.add('in');
   }

   closePop() {
      $('li.cur').removeClass('cur');
      this.pop.classList.remove('in');
   }
}