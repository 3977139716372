import { Pop } from './modules/pop';

global.remToPx = parseFloat(getComputedStyle(document.documentElement).fontSize);

let navAllowed = true;

var txAnimAllowed = true;
var txAnimIdx = 0;
var txAnimIdxPlus = 1;
var txAnimElement;
var txAnimLtrs = [];
let refreshWsTimeout;

function animate(){
   if(txAnimAllowed && txAnimIdx < txAnimLtrs.length) {
      for(var i=txAnimIdx; i < txAnimIdx+txAnimIdxPlus; i++){
         if(i < txAnimLtrs.length){
            txAnimLtrs[i].removeAttribute('style');
            //$(txAnimLtrs[i]).css('opacity',1);
         }
         //console.log(txAnimIdx+i);
      }
      txAnimIdx += Math.round(txAnimIdxPlus);
      txAnimIdxPlus *= 1.04;
   }
   requestAnimationFrame(animate);
}

document.addEventListener('DOMContentLoaded', function() {
   window.pop = new Pop();

   requestAnimationFrame(animate);

   // $('nav a:not(.home)').click(function (e) {
   //    e.preventDefault();
   //    $('div.act').removeClass('act');
   //    const id = $(this).attr('href');
   //    $(id).addClass('act');
   // });


   $('#burger').on('click', function(e){
      e.preventDefault();
      $('html').toggleClass('nav-open');
      
      if(!$('html').hasClass('nav-open')){ // close menu
         $('.act').removeClass('act');
         $('.was-act').addClass('act');
         $('.was-act').removeClass('was-act');
      } else { // open menu
         if($('main > .act').length==0){
            $('#home').addClass('act');
         }
         $('.was-act').removeClass('was-act');
         $('.act').addClass('was-act');
         $('.act').removeClass('act');
      }
      
      $('nav > *').each(function(){
         let rX = 90; // Math.random()*2 - 1;
         let rZ = Math.random()*20 - 10;
         let tY = Math.random()*4 - 2;
         $(this).addClass('notrans').css({
            'transform' : 'scale(0.5) translate3d(0, '+tY+'rem, 0) rotateX('+rX+'deg) rotateZ('+rZ+'deg)',
         }).removeClass('notrans');
      });
   });

   $('.togsub > p').off('click').on('click', function(e){
      e.preventDefault();
      
      let item = $(this).parent();
      
      $(item).toggleClass('on');
      var href = $(item).attr('href');
      
      if($(item).hasClass('on')) {
         $('html').removeClass('nav-open');
         $('main > .act').removeClass('act');
         $(href).addClass('act');
         
         let childs = $(href).children('section');
         if($(childs).filter('.act').length==0){
            $(childs).first().addClass('act');
            $(item).find('.submenu').find('li').first().addClass('yep');
         }
         
         $('.bgvid-iframe').addClass('blur');

         $(item).find('.submenu').find('li').each(function(idx){
            let t = $(this);
            setTimeout(function(){
               $(t).toggleClass('in');
            }, 80 * idx);
         });
      } else {
         $(href).removeClass('act');
         $(item).find('.submenu').find('li').removeClass('in');
      }
      
      if($('main > .act').length==0) {
         $('#home').addClass('act');
         $('.bgvid-iframe').removeClass('blur');
      } else {
         $('#home').removeClass('act');
      }
   });

  $('a.nav, .first a[href*="#"]').on('click', function(e){
   e.preventDefault();
   //console.log(Main.camera.position);
   if(!navAllowed) return false;
   $('html').removeClass('nav-open');
   // navAllowed = false;
   // txAnimElement = null;
   var href = $(this).attr('href');

   let wasActive = $(this).hasClass('act');
   $('a.nav.act').removeClass('act');
   
   $('.togsub').removeClass('on');
   $($('.togsub').attr('href')).removeClass('act');
   $('.togsub').find('.in').removeClass('in');

   let hueShiftVal = Math.random() * 70 - 20;
   $('.bgvid').css('filter','hue-rotate('+hueShiftVal+'deg)');

   clearTimeout(refreshWsTimeout);
   refreshWsTimeout = setTimeout(function(){
      let inactiveWs = $('.info:not(.act) .w');
      for(let i=0;i<inactiveWs.length;i++){
         inactiveWs[i].removeAttribute('style');
         let rX = 90 + (Math.random()*30 - 15); // Math.random()*2 - 1;
         let rZ = Math.random()*50 - 25;
         $(inactiveWs[i]).css({
            'opacity' : 0,
            'transform' : 'rotateX('+rX+'deg) rotateZ('+rZ+'deg)',
         });
      }
   }, 1200);
   
   if(wasActive){
     $('main > .act').removeClass('act');
     $(href).removeClass('act');
     $('nav, footer').removeClass('black');
     txAnimAllowed = true;

     $('.togsub').not(this).removeClass('act on');
     $('.togsub').not(this).parent().find('li').removeClass('in');

     $('.bgvid-iframe').removeClass('blur');

     if($('main > .act').length==0) {
       $('#home').addClass('act');
     } else {
       $('#home').removeClass('act');
     }
   } else {
     $(this).addClass('act');
     $('.bgvid-iframe').addClass('blur');
     $('#home').removeClass('act');
     let childs = $(href).children();
     $('main > .act').not(href).not(childs).not(this).removeClass('act');
     // $(href).addClass('act');

     window.setTimeout(function(){
      
      $(href).addClass('act').scrollTop(0);
      txAnimLtrs = $(href).find('.w');

      var delay = href=='#bio' ? 300 : 10;
      window.setTimeout(function(){
         txAnimElement = $(href);
         txAnimIdx = 0;
         // txAnimIdxPlus = 4;
         txAnimAllowed = true;
         //   navAllowed = true;
      }, delay);
     } , $('.act').length>0 ? 200 : 10);
   }
 });

 $('a[data-link]').unbind('click').click(function(e){
   e.preventDefault();
   if(!navAllowed) return false;
   navAllowed = false;
   var href = $(this).attr('data-link');
   $('section[data-url].act').addClass('out');
   const t = this;
   setTimeout(function(){
      $('div.act').scrollTop(0);
      $('section[data-url]').removeClass('act');
      $('section[data-url="'+href+'"]').addClass('out act');
      $('.bgvid-iframe').addClass('blur');
      $(t).parent().siblings('.yep').removeClass('yep');
      $(t).parent().addClass('yep');

      setTimeout(function(){
         $('section[data-url="'+href+'"]').removeClass('out');
         navAllowed = true;
      }, 10);
   }, 200);
 });

$('.info, .bio').each(function(){
   const scrollbarWidth = window.innerWidth - this.clientWidth;
   this.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
});

$('#contact').on('scroll', function(){
   if($(this).scrollTop() > remToPx * 16) {
      $('#contact .top').addClass('out');
   } else {
      $('#contact .top').removeClass('out');
      let scrollTop = $(this).scrollTop();
      let maxScroll = remToPx * 16;
      let opacity = 1 - (scrollTop / maxScroll);
      let blur = (scrollTop / maxScroll) * 10;
      $('#contact .top').css({
         'opacity': opacity,
         'filter': `blur(${blur}px)`
      });
   }
});
});